/* 
==========
Imports
==========
*/

/* Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&display=swap');
/*
===========
Variables
===========
*/

:root {
	--primary-brand-color: #bce6ff;
	--secondary-brand-color: #2a035beb;
	--primary-bg-color: #f5f5f5;
	--secondary-bg-color: white;
	--generic-dark: #000000;
	--primary-font: 'Poppins', sans-serif;
	--secondary-font: 'Montserrat', sans-serif;
	--hero-font-size: 70px;
	--big-font-size: 50px;
	--medium-font-size: 35px;
	--small-font-size: 20px;
	--border-radius: 20px;
	--line-height: 1.5;
	--letter-spacing: 0.7em;
	--box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.06);
	--transition: all 0.3s linear;
}

/* 
===========
Global styles
===========
*/

*,
::before,
::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: var(--primary-font);
}

body {
	font-style: normal;
}

textarea,
input {
	outline: transparent;
}

.medical-team-box,
.vision,
.solution-box {
	padding: 10%;
}

/* 
=============
Hero
=============
*/

.hero-container {
	padding: 2rem;
	background-color: var(--primary-bg-color);
}

.hero-top {
	display: flex;
}

.brand-logo {
	width: 100px;
}

.social-icon {
	font-size: 1.5rem;
	letter-spacing: var(--letter-spacing);
	color: var(--secondary-brand-color);
	margin: 10px;
}

.hero-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.hero-top-social {
	gap: 37px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hero-top-social a {
	background: #fff;
	border-radius: 50%;
	padding: 2px;
	width: 50px;
	height: 50px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.hero-main {
	position: relative;
	top: 5vh;
	display: flex;
	align-items: center;
	width: 100%;
	padding-bottom: 150px;
	gap: 1rem;
}

.hero-img {
	width: 100%;
}

.manage-hero-img-size {
	width : 60%;
}

.hero-text {
	width : 40%;
}

.hero-text h1 {
	font-weight: 700;
	font-size: 3rem;
	line-height: 76px;
	letter-spacing: 0.07em;
	color: var(--secondary-brand-color);
	padding-bottom: 2rem;
}

.hero-text p {
	font-weight: 400;
	font-size: 1.5rem;
	line-height: 36px;
	letter-spacing: 0.07em;
	color: #000000;
}

/* 
============
Waitlist 
============
*/

.waitlist-box {
	width: 60%;
	margin: 0 auto;
	position: relative;
	background-color: var(--primary-brand-color);
	text-align: center;
	padding: 3%;
	border-radius: var(--border-radius);
	transition: var(--transition);
	box-shadow: var(--box-shadow);
}

.waitlist-top {
	margin-top: -50px;
}

.waitlist-heading {
	padding-bottom: 20px;
	font-size: 2rem;
	font-weight: 600;
	line-height: 48px;
	letter-spacing: 0.07em;
	color: var(--generic-dark);
}

.waitlist-btn {
	padding: 3%;
	border-width: 0;
}

.waitlist-btn {
	width: 20%;
	border-radius: 11.6924px;
	background-color: var(--secondary-brand-color);
	color: var(--primary-bg-color);
	font-family: var(--primary-font);
	box-shadow: var(--box-shadow);
	font-weight: 400;
	font-size: 2rem;
	transition: var(--transition);
	cursor: pointer;
}

.waitlist-btn:hover {
	background-color: #610c63;
}

/* 
=============
Problem
=============
*/

.medical-team-box {
	display: flex;
	align-items: center;
	gap: 124px;
	background-color: var(--secondary-bg-color);
}

.medical-team-img {
	width : 50%;
}

.medical-team-img img {
	width : 100%;
}

.medical-team-text {
	width : 60%;
}

.medical-team-text h2,
.medical-team-text p {
	letter-spacing: 0.07em;
	color: #000000;
}

.medical-team-text h2 {
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 60px;
	padding-bottom: 30px;
}

.medical-team-text p {
	font-weight: 400;
	font-size: 1.5rem;
	line-height: 36px;
}

.problem-list *{
	color: #000000;
	font-size : 1.5rem;
	line-height: 2;
	list-style-type: none;
}

.top-ellipse {
	position: absolute;
	right: 0;
	top: 230%;
	z-index: 1;
}

/* 
==========
Vision
==========
*/

.vision {
	margin-top: 5%;
}

.vision-box {
	background-color: var(--secondary-bg-color);
	display: flex;
	text-align: center;
	flex-wrap: wrap;
	gap: 2.5rem;
	margin-top: 10%;
	justify-content: center;
	align-items: center;
}

.vision-heading {
	text-align: center;
	background-color: var(--secondary-bg-color);
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 60px;
	letter-spacing: 0.07em;
	color: var(--generic-dark);
	padding-bottom: 30px;
}

.vision-item {
	display: flex;
	align-items: center;
	background-color: var(--primary-brand-color);
	border-radius: var(--border-radius);
	padding: 2rem;
	text-align: center;
	transition: var(--transition);
}

.vision-item p {
	font-weight: 400;
	font-size: 1.125rem;
	line-height: 30px;
	letter-spacing: 0.07em;
	color: #000000;
}

.vision-item {
	box-shadow: var(--box-shadow);
}

.vision-item-image {
	position : relative;
	top : -70px;
}

/* 
============
Solution
============
*/

.solution-box {
	display: flex;
}
.solution-wrapper {
	position: relative;
}

.solution-text {
	display: flex;
	flex-direction: column;
}

.solution-text h2 {
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 60px;
	padding-bottom: 30px;
	color: var(--generic-dark);
	letter-spacing: 0.07em;
	padding-bottom: 30px;
}

.solution-text p {
	font-weight: 400;
	font-size: 1.5rem;
	line-height: 36px;
	letter-spacing: 0.07em;
	color: var(--generic-dark);
}

.solution-box-img {
	position: absolute;
	left: 0;
	top: 0;
}

/* 
==========
Footer
==========
*/

footer {
	padding: 5% 30% 2% 30%;
	background-color: var(--primary-bg-color);
	margin-top: 70px;
}

.footer-inner {
	display: flex;
	justify-content: space-evenly;
}

footer p {
	font-size: 15px;
	font-family: var(--secondary-font);
}



.footer-logo {
	width: 100%;
}

.footer-social-area {
	position: relative;
}

.footer-social-icon {
	position: absolute;
	left: 0;
	gap: 27px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0.5rem;
}

.footer-social-icon a {
	background: #fff;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.footer-links-heading {
	font-size: 1.25rem;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.07em;
	color: #000000;
	padding-bottom: 30px;
	font-family: var(--secondary-font);
}

.sublink p a {
	text-decoration: none;
	color: black;
	text-transform: capitalize;
	font-family: var(--secondary-font);
	padding-bottom: 0.5rem;
	font-size: 1.25rem;
	line-height: 24px;
	letter-spacing: 0.07em;
}

.footer-links-area {
	display: flex;
	justify-content: space-around;
	font-family: var(--secondary-font);
}

.copyright {
	text-align: center;
	background-color: var(--primary-bg-color);
	font-family: var(--secondary-font);
	padding: 7rem 0 2.8125rem 0;
}

/* mobile screen */

@media screen and (max-width: 768px) {
	.hero-main,
	.vision-box,
	.solution-box {
		flex-direction: column;
	}

	.vision-item {
		margin : 20px 10px;
	}

	.vision-item-image {
		width : 20%;
		top : -60px;
	}

	.vision-box {
		gap: 1rem;
	}

	.hero-text h1 {
		font-size: 2rem;
	}

	.hero-top-social {
		gap: 10px;
	}

	img {
		width: 100%;
	}
}

@media screen and (max-width: 1024px) {
	.hero-text h1 {
		font-size: 2rem;
		line-height: 46px;
		padding-bottom: 1rem;
	}

	.hero-text {
		width : 90%;
	}

	.medical-team-box {
		flex-direction: column;
	}

	.medical-team-text {
		width : 100%;
	}

	.solution-img {
		margin-top: 30px;
	}

	.solution-img img {
		width: 100%;
	}
}

@media screen and (max-width: 1350px) and (min-width: 1140px) {
	.medical-team-img img {
		width: 300px;
	}
}

@media screen and (max-width: 900px) {
	.footer-inner {
		flex-direction: column;
	}

	.footer-brand-area {
		margin-bottom: 5rem;
	}

	.footer-links-area {
		justify-content: space-between;
		width: 100%;
	}

	.footer-logo-area {
		padding-bottom: 3rem;
	}
}

@media screen and (max-width: 600px) {
	.footer-links-area {
		flex-direction: column;
		gap: 3rem;
	}

	.footer-links-heading {
		padding-bottom: 0.5rem;
	}

	.hero-img {
		display: none;
	}

	.hero-top {
		gap: 1rem;
	}

	.hero-top-social a {
		padding: 0;
		width: 30px;
		height: 30px;
	}

	.waitlist-box {
		width: 95%;
	}

	.waitlist-input,
	.waitlist-btn {
		font-size: 1rem;
	}

	.waitlist-heading {
		font-size: 1.5rem;
		padding-bottom: 0.5rem;
	}

	.waitlist-sub {
		font-size: 0.8rem;
		padding-top: 0.5rem;
	}
}

/* large screen */

@media screen and (min-width: 1550px) {
	.hero-inner,
	.medical-team-wrapper,
	.vision,
	.solution-box,
	.footer-inner {
		width: 1440px;
		margin: 0 auto;
	}
}

@media screen and (min-width: 768px) {
	.vision-item {
		width: 370px;
	}

	.hero-inner {
		width: 90%;
		margin: 0 auto;
	}

	.footer-brand-area {
		width: 20%;
	}
	.footer-links-area {
		width: 80%;
	}
}

@media screen and (min-width: 1024px) {
	.footer-logo-area {
		padding-bottom: 70px;
		padding-right: 0;
	}
}
